import { dateTimeHelper } from "./DateTimeHelper";
/**
 * 管理No共通
 */
export default class ControlNumberUtil {
  // 基準日
  UnixEpoch = new Date(Date.UTC(2022, 0, 1, 0, 0, 0));

  // 発行日時として使用する領域のbit数
  TimestampBits = 31n;
  // ユーザーIDとして使用する領域のbit数
  UserIdBits = 27n;
  // 発行連番として使用する領域のbit数
  SequenceBits = 6n;

  // 発行日時として使用する領域に対するマスク
  MaxTimestamp = -1n ^ (-1n << this.TimestampBits);
  // ユーザーIDとして使用する領域に対するマスク
  MaxUserId = -1n ^ (-1n << this.UserIdBits);
  // 発行連番として使用するに領域に対するマスク
  MaxSequence = -1n ^ (-1n << this.SequenceBits);

  // 発行日時の移動ビット数
  TimestampLeftShift = 64n - this.TimestampBits;
  // UserIdの移動ビット数
  UserIdLeftShift = this.TimestampLeftShift - this.UserIdBits;

  // 最終発行日時
  lastTimestamp = -1n;
  // 発行連番
  sequence = 0n;

  /**
   * ユニークな値の生成
   */
  createControlNumber(userId) {
    // ID判定
    // if (userId > this.MaxUserId || userId < 0) {
    //   throw "user Id can't be greater than " + this.MaxUserId + " or less than 0";
    // }

    // 現在時刻の取得
    var timestamp = this.timeGen();

    // 更新日付の比較
    if (timestamp < this.lastTimestamp) {
      // 最終更新日時不正
      throw "最終更新日時不正";
    }

    // 最終更新日付の判定
    if (this.lastTimestamp === timestamp) {
      // 最終更新日付と同じ場合、連番を加算
      this.sequence = (this.sequence + 1n) & this.MaxSequence;
      if (this.sequence === 0n) {
        timestamp = this.lastTimestamp;
      }
    } else {
      // 最終更新日付の方が新しいので連番を初期化
      this.sequence = 0n;
    }

    // 最終更新日付の更新
    this.lastTimestamp = timestamp;

    let l = BigInt(
      (BigInt(this.lastTimestamp & this.MaxTimestamp) << this.TimestampLeftShift) |
        (BigInt(BigInt(userId) & this.MaxUserId) << this.UserIdLeftShift) |
        BigInt(this.sequence & this.MaxSequence)
    );

    // 16進数の0埋め
    let hex = this.toHex(l);

    return hex;
  }

  /**
   * 基準日時と発行日時の差分（秒）
   */
  timeGen() {
    return BigInt(Math.floor(Date.now() / 1000) - Math.floor(this.UnixEpoch.getTime() / 1000));
  }

  /**
   * 数値を１６進数文字列に変換
   * @param {*} v
   * @returns
   */
  toHex(v) {
    return ("0000000000000000" + v.toString(16).toUpperCase()).slice(-16);
  }

  /**
   * 管理Noから発行日時の取得
   */
  getDateTime(controlNumber) {
    var l = 0n;
    for (let index = 0; index <= controlNumber.length / 2 - 1; index++) {
      let pointer = index * 2;
      let s = controlNumber.substring(pointer, pointer + 2);
      l = (l << 8n) | BigInt(parseInt(s, 16));
    }
    let msc = (l >> this.TimestampLeftShift) & this.MaxTimestamp;
    let date = new Date(Date.UTC(2022, 0, 1, 0, 0, 0));
    date.setSeconds(date.getSeconds() + Number(msc));

    return date;
  }

  /**
   * 管理NoからユーザーIDの取得
   */
  getUserId(controlNumber) {
    let l = 0n;
    for (let index = 0; index <= controlNumber.length / 2 - 1; index++) {
      let pointer = index * 2;
      let s = controlNumber.substring(pointer, pointer + 2);
      l = (l << 8n) | BigInt(parseInt(s, 16));
    }

    // return this.MaxUserId;
    return (l >> this.UserIdLeftShift) & this.MaxUserId;
  }

  /**
   * 管理Noから連番の取得
   */
  getSequence(controlNumber) {
    let l = 0n;
    for (let index = 0; index <= controlNumber.length / 2 - 1; index++) {
      let pointer = index * 2;
      let s = controlNumber.substring(pointer, pointer + 2);
      l = (l << 8n) | BigInt(parseInt(s, 16));
    }
    return l & this.MaxSequence;
  }

  // 16進数になっている管理番号表示用
  getDecodeItemManageNo(controlNumber) {
    return (
      dateTimeHelper.toStringDate("YYYYMMDDhhmmss", controlNumberUtil.getDateTime(controlNumber)) +
      "_" +
      controlNumberUtil.getUserId(controlNumber).toString() +
      "_" +
      ("00" + Number(controlNumberUtil.getSequence(controlNumber))).slice(-2)
    );
  }
}

export const controlNumberUtil = new ControlNumberUtil();
